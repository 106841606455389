* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  background: #fff;
  color: #212121;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  font-family: 'Poppins', sans-serif, Arial;
}
ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}
:focus {
  outline: none;
}
a {
  text-decoration: none;
}
button {
  cursor: pointer;
}
figure {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.data-table{
  .ant-table-body{
    min-height: calc(100vh - 280px);
  }
}

